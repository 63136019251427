import React, { useEffect, useState } from 'react';
import './credito.css';

const Credito = ({ lastUsedCredit, creditCount, onCreditUpdated }) =>{
  const [tiempoRestante, setTiempoRestante] = useState(calculateRemainingTime(lastUsedCredit));

  useEffect(() => {
    if (creditCount >= 20) {
      setTiempoRestante(0);
      return;
    }

    const interval = setInterval(() => {
      const nuevoTiempoRestante = calculateRemainingTime(lastUsedCredit);
      setTiempoRestante(nuevoTiempoRestante);

      if (nuevoTiempoRestante <= 0) {
        onCreditUpdated();
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastUsedCredit, creditCount, onCreditUpdated]);

  function calculateRemainingTime(lastUsedCredit) {
    const dosHoras = 2 * 60 * 60 * 1000;
    const cincoHoras = 5 * 60 * 60 * 1000;

    const now=Date.now()
    const lastTime=new Date(lastUsedCredit).getTime()
    const tiempoTranscurrido = now - lastTime-cincoHoras ;
    const tiempoRestante = cincoHoras - tiempoTranscurrido;



    return tiempoRestante > 0 ? tiempoRestante : 0;
  }

  function formatTime(ms) {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if(hours>0)
      return `${hours}h ${minutes}m ${seconds}s`;
    else
      return `${minutes}m ${seconds}s`;

  }


  return (
    <div className="tooltip-container">
      <i className="fas fa-stopwatch" style={{color: '#00bb00', fontSize: '20px', marginLeft: '5px'}}></i>
      <div className="tooltip">
        +1 crédito en: {formatTime(tiempoRestante)}
      </div>
    </div>
  )
}

export default Credito;