import React from "react";
import { ChangeEvent, KeyboardEvent, MouseEvent, useState } from "react";
import { addMessage } from "../lib/api";
import { ChatMessage, Room, Usuario } from "../lib/types";
import { API_BASE_URL } from "../lib/constants";

interface ChatFormProps {
  room: Room | undefined,
  usuarioId: String 
}

const ChatForm = ({ room, usuarioId }: ChatFormProps) => {
  const [message, setMessage] = useState("");
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  async function addMessage(roomId, message) {
    const data = await fetch(`${API_BASE_URL}/chats`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer  ${localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        roomId,
        message,
        senderId: usuarioId,
        receiverId: room?.destinatarioId
      }),
    });
    return data.status;
  }

  const sendMessage = () => {
    if (message !== "") {
      addMessage(
        room?.roomId,
        message
      );

      setMessage("");
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const handleSubmit = (e: MouseEvent) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <div className="col-md-12">
      <form className="col-md-12">
        <div className="form-group col-md-11">
          <div className="d-flex">
            <div className="offset-md-1 col-md-10 ps-5 input-message" style={{alignContent: 'center'}}>
              <input className="form-control p-2" id="message" type="text" placeholder="Escribe un mensaje" value={message}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-1 button-message">
              <button className="btn btn-success" onClick={handleSubmit} type="submit">
                <i className="fa fa-arrow-right" style={{fontSize:"30px"}}></i>                
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChatForm;
