import React from "react";
import { useState, useEffect, FC } from 'react';
import { Room, Usuario } from '../lib/types.tsx';
import RoomRow from './RoomRow.tsx';
import { API_BASE_URL, tokenBearer } from "../lib/constants.js";


interface RoomListProps {
  usuario: Usuario;
  roomActual: (room: Room) => void;
  room: Room | undefined;
}

const RoomList: FC<RoomListProps> = ({ usuario, roomActual, room }) => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [selectedRoomId, setSelectedRoomId] = useState<string | null>(null);

  useEffect(() => {
    const source = new EventSource(
      `${API_BASE_URL}/rooms/stream?usuarioId=${usuario.id}`
    );

    source.onmessage = (event) => {
      console.log("ejecutandose -----------------------")
      const newRoom = JSON.parse(event.data) as Room;
      setRooms((prevMessages) => [...prevMessages, newRoom]);

      if(localStorage.getItem("seleccionarChat")){
        console.log("buscando "+ newRoom.destinatarioId+"   "+localStorage.getItem("seleccionarChat"))
        if(newRoom.destinatarioId==localStorage.getItem("seleccionarChat")){
          actualizarRoom(newRoom)
          console.log("no enc")
        }
        else
          console.log("no enc")
      }
      else
        console.log("no local storage")
    };

    return () => {
      source.close();
    };
  }, []);

  const findRoomByUsuarioId = (usuarioId) => {
    return rooms.find(room => room.destinatarioId === usuarioId);
  };

  useEffect(()=>{
    if(room==undefined){
      setSelectedRoomId(null)
    }
  }, [room])

  
  const actualizarRoom = (room: Room) =>{
    roomActual(room)
    setSelectedRoomId(room.id || null)
    localStorage.setItem("seleccionarChat", room?.destinatarioId)
    console.log("room actual "+roomActual)
  }

  const list = rooms.map(
    (row) => <RoomRow key={row.id} usuario={usuario} room={row} isSelected={selectedRoomId === row.id} onClick={()=>actualizarRoom(row)}/>
  );

 
  return (
    <div className='col-md-12'>
      <div className="form-row">
        <h2 className='text-center p-1 pt-4' style={{color:"white", fontWeight: '900'}}>Chats</h2>
        <hr className="col-md-12"/>
      </div>
      <ul className="roomlist p-0 m-1">
        {list}
      </ul>
    </div>
  )
};

export default RoomList;