import React, { Component, FC, useEffect, useRef, useState } from "react";
import { Alert } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import "./estilo.css";
import logo from "../../../../src/icono_match_express.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {MessageData, NotificationMessage, Usuario} from '../../lib/types';
import { API_BASE_URL, tokenBearer } from "../../lib/constants";
import { getElapsedTimeInSeconds } from '../../lib/api.ts';
import axios from "axios";

interface AppHeaderListProps {
  authenticated: boolean | false;
  onLogout: () => void;
  usuario: Usuario;
}

const AppHeader: FC<AppHeaderListProps> = ({authenticated, onLogout, usuario})=> {
    const spanNumberMessages = useRef<HTMLSpanElement>(null);
    const [numberMessages, setNumberMessages] = useState<string | null>(null);
    
    const [notificacion, setNotificacion] = useState<NotificationMessage[]>([]);
    const navigate = useNavigate();


    const handleGoPerfil=()=>{
      navigate("/profile")
    }

    const handleGoMain=()=>{
      navigate('/main')
    }
    const handleGoChats=()=>{
        navigate('/chats')
    }
    
    /* const handleClick=()=>{
      axios.post(
        `${API_BASE_URL}/api/v1/notifications`,
        [],
        tokenBearer
      ).then(res =>{
        console.log(res)
      });
    } */
    useEffect(() => {
        if(authenticated){
          const sourceNot = new EventSource(`${API_BASE_URL}/api/v1/notifications/stream?userId=${usuario.id}`);
          sourceNot.onmessage = (event) => {
            const newMessage = JSON.parse(event.data) as NotificationMessage;

            if(newMessage.state===1 && getElapsedTimeInSeconds(newMessage.createdDate)<=1)  toast.info(newMessage.description)
            handleNewNotification(newMessage);
          };
          return () => {
            sourceNot.close();
          };
        }
    }, [authenticated]);

    useEffect(() => {
      if(authenticated){
        const sourceMessages = new EventSource(`${API_BASE_URL}/messageData/stream?userId=${usuario.id}`);
        sourceMessages.onmessage = (event) => {
          const newMessageData = JSON.parse(event.data) as MessageData;

          if(newMessageData?.noReaded>0){
            setNumberMessages(newMessageData?.noReaded)
            if(spanNumberMessages.current)  spanNumberMessages.current.innerText=""+newMessageData?.noReaded
          }
          else{
            setNumberMessages("")
            if(spanNumberMessages.current)  spanNumberMessages.current.innerText=""
          }
        };
        return () => {
          sourceMessages.close();
        };


        
      }
    }, [authenticated]);

    const handleNewNotification = (newMessage: NotificationMessage) => {
      setNotificacion((prevNotification) => {
        const updatedNotifications = [newMessage, ...prevNotification]; // Agrega la nueva notificación al inicio
        if (updatedNotifications.length > 5) { // Mantén solo las últimas 5 notificaciones, por ejemplo
          updatedNotifications.pop(); // Elimina la última notificación
        }
        return updatedNotifications;
      });
    };

    return (
      <>
        <div className="navbar navbar-default navbar-fixed-top" style={{height: '10vh'}}>
            <div className="container">
                <div className="navbar-header col-md-12" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <a href="/" className="navbar-brand" style={{marginRight:'auto'}}>
                      <img src={logo} style={{width:'130px', transform: 'translateY(-50%)', position:'absolute'}}/>
                    </a>
                    {authenticated?
                        (
                          <div style={{display: 'flex'}} className="marco-menu-botones">
                            <a title="Buscar Amigos"  onClick={handleGoMain} className="rounded-circle m-2 boton-circular">
                                <i className="fas fa-users text-dark"></i>
                            </a>

                            <div className="rounded-circle" style={{position:'relative'}}>
                              <a title="Ir a la Ventana de Chats" onClick={handleGoChats} className="rounded-circle m-2 boton-circular">
                                <i className="fas fa-comment text-dark"></i>
                              </a>
                              <span ref={spanNumberMessages} style={{position:'absolute', top:'30px', left:'35px', fontWeight:'900', fontFamily:'system-ui'}}>{numberMessages}</span>
                            </div>
                            

                            <a title="Editar tú Perfil" onClick={handleGoPerfil} className="rounded-circle m-2 boton-circular">
                                <i className="fas fa-edit text-dark"></i>
                            </a>

                            <span className="ms-1 lbl-etiqueta" style={{fontWeight:'900',alignContent:'center',justifyItems:'center',fontSize: '20px', color: '#0068ff'}}>
                              {usuario.nombre}
                            </span>

                            <button title="Cerrar Sesión" className="ms-1 btn btn-inline-danger rouded-circle" onClick={onLogout}>
                                <i className="fas fa-sign-out-alt text-danger" style={{fontSize:'40px'}}></i>
                            </button>
                          </div>
                        )
                        :
                        ('')
                    }
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="nav navbar-nav navbar-right">
                        <li><a href="/home" className="smoothScroll">Inicio</a></li>
                        <li><a href="/profile" className="smoothScroll">Perfil</a></li>
                        <li><a href="/matchs" className="smoothScroll">Tus matchs</a></li>
                        <li><a href="#pricing" className="smoothScroll">Precios</a></li>
                        <li><a href="#about" className="smoothScroll">Acerca de</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={2500}
          hideProgressBar={true}
        />
      </>
    );

    
}

export default AppHeader;


