import React, { useEffect, useRef, useState } from 'react'
import {Profile, Usuario, UsuarioDto} from '../../lib/types.tsx';
import { EventSourcePolyfill } from 'event-source-polyfill';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import { API_BASE_URL, tokenBearer } from '../../lib/constants.js';
import UserRow from './UserRow.tsx';
import './principal.css';
import Credito from './Credito.tsx';

const Principal=({usuario, authenticated, onCreditUpdated})=>{
    const [usuarios, setUsuarios] = useState<UsuarioDto[]>([]);
    const [mostrarGif, setMostrarGif] = useState(false);
    const [searchTerm, setSearchTerm] = useState('')
    const [tipoBusqueda, setTipoBusqueda] = useState(2)

    const aMatches = useRef<HTMLAnchorElement>(0);
    const aSugerencias = useRef<HTMLAnchorElement>(0);
    const [isCheckedCiudad, setIsCheckedCiudad] = useState(false);
    const [selectGenero, setSelectGenero] = useState('');

    const [creditCount, setCreditCount] = useState(0);
    const [lastUsedCredit, setLastUsedCredit] = useState(new Date().toISOString());

    useEffect(() => {
      if(aMatches.current.style!== undefined  && aSugerencias.current.style!== undefined){
        if(tipoBusqueda==1){
          aMatches.current.style.fontWeight="900"
          aMatches.current.style.setProperty('text-decoration', 'underline', 'important')
          aSugerencias.current.style.fontWeight="normal"
          aSugerencias.current.style.setProperty('text-decoration', 'none')
        }
        else{
          aMatches.current.style.fontWeight="normal"
          aMatches.current.style.setProperty('text-decoration', 'none')
          aSugerencias.current.style.fontWeight="900"
          aSugerencias.current.style.setProperty('text-decoration', 'underline', 'important')
        }
      }

      setTimeout(()=>{
        setMostrarGif(true);
      }, 700)

      const delayDebounceFn = setTimeout(() => {
        buscarPersonas(searchTerm)
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }, [searchTerm, tipoBusqueda])


    useEffect(() => {
      setSelectGenero(usuario.interes)
      setCreditCount(usuario.cantidadCreditos)
      setLastUsedCredit(usuario.lastUsedCredit)

      console.log("actualizando "+usuario.cantidadCreditos)
    }, [usuario]);

    useEffect(()=>{
      setTimeout(()=>{
        setMostrarGif(true);
      }, 400)
      setTimeout(()=>{
        buscarPersonas()
      },1000)
    }, [isCheckedCiudad, selectGenero])

    if(!authenticated){
      return (
          <Navigate to="/login"/>
      );
    }
    else{
      if(!usuario.filledProfile){
        return (
          <Navigate to="/profile"/>
        );
      }
    }

    const handleCheckboxChange = (event) => {
      setIsCheckedCiudad(event.target.checked);
    };
    const handleSelectGeneroChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = e.target;
      setSelectGenero(value)
    };
    
    function buscarPersonas(parametro=""){
        var parametro3="&ciudad="+isCheckedCiudad
        var parametro4="&interes="+selectGenero

        if(selectGenero=="") return;

        axios.get(
            `${API_BASE_URL}/api/v1/users/getMatches${"?param="+parametro}${"&tipo="+tipoBusqueda}${parametro3}${parametro4}`,
            tokenBearer
        ).then(res => {
            if(res.data){
                setUsuarios([])
                for(var i=0; i<res.data.length; i++){
                    const newUser = {
                        'id': res.data[i]['id'],
                        'nombre': res.data[i]['nombre'],
                        'correo': res.data[i]['correo'],
                        'facebook': res.data[i]['facebook'],
                        'instagram': res.data[i]['instagram'],
                        'foto': res.data[i]['foto'],
                        'edad': res.data[i]['edad'],
                        'sexo': res.data[i]['sexo'],
                        'compatibilidad': res.data[i]['compatibilidad'],
                        'interes': res.data[i]['interes'],
                        'ciudad': res.data[i]['ciudad'],
                        'pais': res.data[i]['pais']
                    };

                    setUsuarios((prevMessages) => [...prevMessages, newUser]);
                }

                setTimeout(()=>{
                  setMostrarGif(false)
                }, 500)
            }
        })
    }
    
    const listaUsuarios = usuarios.map((u) => <UserRow key={u.id} userMatch={u} match={tipoBusqueda==1? true: false}/>);

    return (
        <div>
            <section className="pt-4" id="pricing">
                <div className="container">
                    <div className="row botones-busqueda">
                        <div className="wow fadeInUp col-lg-8 col-md-6 btn-compra" data-wow-delay="0.4s" style={{display:'flex', alignItems: 'center', justifyContent: 'right'}}>
                            <a href="/buy" className="btn btn-sm btn-primary">
                                <i className="fas fa-shopping-bag" style={{color: 'white', fontSize: '20px', fontWeight: '900'}}></i>
                                <span className='ms-1 etiqueta' style={{fontSize:'20px'}}>Comprar</span>
                            </a>
                        </div>
                        <div className="wow fadeInUp col-lg-2 col-md-3 ps-0 estadistica" data-wow-delay="0.4s">
                            <div className="card-body border-bottom p-0" style={{background: "white", borderRadius: "5px"}}>
                              <div className="d-flex align-items-center px-2 py-1">
                                <i className="fa fa-bolt ps-2" style={{color: '#ff3f4f', fontSize: '20px', fontWeight: '900'}}></i>
                                <h3 className={`f-w-300 m-0 p-0 ps-4`} style={{fontSize: '20px', fontWeight:'900', background: "white", borderRadius: "5px", textAlign:'right'}}>
                                    {usuario?.cantidadCreditos}
                                </h3>
                                <span className="d-block text-uppercase lbl-cantidad" style={{fontSize:'14px', fontWeight:'bold'}}>CREDITO{usuario?.cantidadCreditos>1 ? ('S'):('')}</span>
                                
                                {creditCount<20 ?
                                  <Credito lastUsedCredit={lastUsedCredit} creditCount={creditCount} onCreditUpdated={onCreditUpdated} />
                                  :
                                  ''
                                }
                              </div>
                            </div>
                        </div>
                        <div className="wow fadeInUp col-lg-2 col-md-3 ps-0 estadistica" data-wow-delay="0.4s">
                            <div className="card-body border-bottom p-0" style={{background: "white", borderRadius: "5px"}}>
                              <div className="d-flex align-items-center px-2 py-1">
                                <i className="fas fa-user-friends ps-2" style={{color: '#ff3f4f', fontSize: '20px', fontWeight: '900'}}></i>
                                <h3 className="f-w-300 m-0 p-0 ps-5" style={{fontSize: '20px', fontWeight:'900', background: "white", borderRadius: "5px", textAlign:'right'}}>
                                    {usuario?.cantidadMatches}
                                </h3>
                                <span className="d-block text-uppercase lbl-cantidad" style={{fontSize:'14px', fontWeight:'bold'}}>MATCHES</span>
                              </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="wow fadeInUp col-md-12 col-sm-12 pt-1 marco-resultados" data-wow-delay="0.6s">
                            <div className="pricing-plan marco-resultados-hijo" style={{borderRadius: "10px", textAlign:"left", padding:"20px"}}>
                                <div className="col-md-12">
                                    <div className="row mx-1" style={{backgroundColor:'white'}}>
                                      <div className="col-md-8 marco-filtro" style={{display:'flex', alignItems:'center'}}>
                                        <div className="col-md-5 flex marco-filtro-1">
                                          <a className='me-4' href="#" onClick={(e) =>{setTipoBusqueda(1)}} ref={aMatches}>Matches</a>
                                          <a className='me-4' href="#" onClick={(e) =>{setTipoBusqueda(2)}} ref={aSugerencias}>Sugerencias</a>
                                        </div>
                                        <div className="col-md-3 marco-filtro-2">
                                          <div className="form-check">
                                            <input className="form-check-input" id="checkCiudad" type="checkbox" checked={isCheckedCiudad} onChange={handleCheckboxChange} />
                                            <label className="form-check-label lbl-etiqueta-1" htmlFor="checkCiudad" style={{fontSize:'14px', fontWeight:'normal'}} >En tu Ciudad</label>
                                            <label className="form-check-label lbl-etiqueta-2" htmlFor="checkCiudad" style={{paddingTop:'2px', fontSize:'14px', fontWeight:'normal', display:'none'}} >Ciudad</label>
                                          </div>
                                        </div>
                                        <div className="d-flex col-md-4 marco-filtro-3">
                                          <label className="form-check-label lbl-etiqueta-1" htmlFor="selectGenero" style={{fontSize:'14px',paddingTop:'4px', fontWeight:'normal'}}>Interés:&nbsp; </label>
                                          <select name="selectGenero" id="selectGenero" value={selectGenero} className="form-select form-select-sm"  onChange={handleSelectGeneroChange}>
                                            <option value="H">Hombres</option>
                                            <option value="M">Mujeres</option>
                                            <option value="O">Otro</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-4 " style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                                        {mostrarGif? <img src="/images/loading.gif" className='me-2' style={{width:'30px', height:'30px'}}/>: ''}
                                        <div className="col-md-10 py-2 marco-busqueda" style={{borderRadius:'20px', position:'relative', background:'#eeeeee'}}>
                                          <i className="fas fa-search pt-1 ps-3 text-secondary" style={{fontSize:'20px', position:'absolute'}}></i>
                                          <input type="text" className="col-md-10 ms-4 ps-3" style={{border:'0px', outline:'none', background:'#eeeeee'}}
                                            onChange={(e) => setSearchTerm(e.target.value)} />
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <hr className='col-md-12'/>
                                <div className="row mx-1" style={{paddingBottom:'100px', minHeight:'400px'}}>
                                  {listaUsuarios}
                                </div>
                                <hr className='col-md-12 mb-1'/>
                                <span className='col-md-12 ' style={{textAlign:'center', display:'block'}}>Resultado: {usuarios.length} coincidencia{usuarios.length>1? 's':''}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Principal;